import { setAuthVar } from '@/config/ApolloClient';
import { createContext, useContext, useState } from 'react';
import {
  getSessionString,
  removeSessionString,
  setSessionString,
  storageSupported,
} from '../utils/setSessionString';

interface AuthContextVariables {
  setAuth: (token: string) => void;
  signOn: string;
  removeAuth: () => void;
  isAuth: () => boolean;
  messageResultStatus: MessageResult;
  setMessageResultStatus: (messageResultStatus: MessageResult) => void;
}

export enum MessageResultStatus {
  UNUSED = 'unused',
  USED = 'used',
  REDIRECT = 'redirect',
  EXPIRED = 'expired',
}

export interface MessageResult {
  status: MessageResultStatus;
  message?: string;
  captchaSiteKey?: string;
  msgReaccess?: string;
}

const AuthContext = createContext({});

export function AuthProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const [signOn, setSignOn] = useState<string>('');
  const [messageResultStatus, setMessageResultStatus] = useState<MessageResult>(
    {
      status: MessageResultStatus.UNUSED,
    }
  );

  const setAuth = (token: string) => {
    setAuthVar(token);
    setSessionString('auth', token);
    setSignOn(token);
  };

  const removeAuth = () => {
    setAuthVar('');
    removeSessionString('auth');
    setSignOn('');
  };

  const isAuth = () => {
    return storageSupported() ? !!getSessionString('auth') : !!setAuthVar();
  };

  return (
    <AuthContext.Provider
      value={{
        signOn,
        setAuth,
        removeAuth,
        isAuth,
        messageResultStatus,
        setMessageResultStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuthContext(): AuthContextVariables {
  const context = useContext(AuthContext) as AuthContextVariables;
  return context;
}
