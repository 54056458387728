import DraftModal from '@/components/DraftModal';
import ErrorPage from '@/components/ErrorPage';
import GlobalFooterBeeliked from '@/components/GlobalFooterBeeliked';
import GlobalHeader from '@/components/GlobalHeader';
import MetaData from '@/components/MetaData';
import SpinnerFirstLoad from '@/components/SpinnerFirstLoad';
import {
  AnalyticsScriptType,
  PromotionStatus,
} from '@/modules/[slug]/interface';
import {
  LayoutProps,
  OnMessageReceiver,
  RefetchStartPageVariables,
} from '@/modules/[slug]/layout.interface';
import usePageStart from '@/modules/[slug]/start/usePageStart';
import useAuthContext, { MessageResultStatus } from '@/providers/AuthProvider';
import useMicrositeContext from '@/providers/MicrositeContextProvider';
import styles from '@/styles/indexStyles.module.scss';
import htmlTagStringToReactComponent from '@/utils/htmlTagStringToReactComponent';
import styleModuleClasses from '@/utils/styleModuleClasses';
import { useResizeObserver } from '@asyarb/use-resize-observer';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';
import useMicrositeLoadingImageContext from './loadingContext';

export default function Layout({ children }: LayoutProps) {
  const refResize = useRef<HTMLDivElement>(null);

  const sizes = useResizeObserver({ ref: refResize });

  const router = useRouter();

  const {
    initial,
    promotionSlug,
    passCodeValue,
    accountSlug,
    setStartPage,
    setSegments,
    inviteLinkCode,
    beelikedLoading,
    setShowPopups,
  } = useMicrositeContext();
  const { removeAuth, setMessageResultStatus } = useAuthContext();
  const { loadingImages, setLoadingImages } = useMicrositeLoadingImageContext();
  const position = router.pathname.lastIndexOf('/') + 1;
  const page = position > 1 ? router.pathname.slice(position) : 'start';

  const { runPageStart, data, loading } = usePageStart();

  const refetchStartPage = ({
    accountSlug,
    promotionSlug,
    inviteLinkCode,
  }: RefetchStartPageVariables) => {
    runPageStart?.(accountSlug, promotionSlug, inviteLinkCode);
  };

  const tags = useMemo(() => {
    if (initial?.analytics?.code_bottom) {
      return initial.analytics.code_bottom
        .filter((script) => script.type === AnalyticsScriptType.IMPORT)
        .map((tag) => htmlTagStringToReactComponent(tag.content));
    }
    return undefined;
  }, [initial?.analytics?.code_bottom]);

  const onMessageReceiver = (event: OnMessageReceiver) => {
    if (event.data.type === 'nextPage') {
      if (event.data.page === 'enter') {
        router.push(`/${promotionSlug}/${event.data.page}`);
      }
    }
  };

  const { globalHeader, beelikedFooter, globalFooter } = useMemo(
    () => ({
      globalHeader: data?.templateGlobalHeader?.['template-header']?.html,
      beelikedFooter: !!data?.templateGlobalFooter?.['beeliked-footer'],
      globalFooter: data?.templateGlobalFooter?.['template-footer']?.html,
    }),
    [data]
  );

  useEffect(() => {
    if (data) {
      setStartPage(data.templatePage);
      setSegments(data.segments);
      setShowPopups(data.showPopups);

      if (data.token) {
        if (data.resultStatus.status) {
          const status: MessageResultStatus =
            MessageResultStatus[
              data.resultStatus.status.toLocaleUpperCase() as keyof typeof MessageResultStatus
            ];

          setMessageResultStatus({
            status,
            message: data.resultStatus.message,
            captchaSiteKey: data.resultStatus.captchaSiteKey,
            msgReaccess: data.resultStatus.msgReaccess,
          });
        }
      }
    }
  }, [data]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'resize',
        value: sizes.height,
      },
      '*'
    );
  }, [sizes]);

  useEffect(() => {
    removeAuth();
    if (accountSlug && promotionSlug) {
      runPageStart?.(accountSlug, promotionSlug, inviteLinkCode);
    }

    window.addEventListener('message', onMessageReceiver);
    return () => window.removeEventListener('message', onMessageReceiver);
  }, []);

  useEffect(() => {
    if (!!loading) {
      setTimeout(() => setLoadingImages(false), 2000);
    }
  }, [loading]);

  if (initial === undefined) {
    const passCode = passCodeValue();
    if (passCode) {
      const promotionSlugQuery = router?.query?.slug ?? '';
      window.location.assign(`/${promotionSlugQuery}/?key=${passCode}`);
      return <p>Loading...</p>;
    } else {
      return (
        <ErrorPage
          msg="The URL you have entered is invalid. Please check and try again."
          showMsg={true}
          showButton={false}
        />
      );
    }
  }
  return (
    <>
      <MetaData
        metaData={initial?.metaData}
        urlTemplateCss={initial?.urlTemplateCss}
        urlThemeCss={initial?.urlThemeCss}
        analytics={initial?.analytics}
        campaignName={initial?.campaignName}
      />
      {initial.status === PromotionStatus.DRAFT && (
        <DraftModal refetchStartPage={refetchStartPage} />
      )}
      {loadingImages && <SpinnerFirstLoad />}

      <>
        <div
          id={`blkd-page-${page}`}
          className={styleModuleClasses(
            styles,
            'blkd-page container-fluid',
            'blkd-page',
            {
              'pt-12': initial?.status === PromotionStatus.DRAFT,
            }
          )}
          ref={refResize}
        >
          {globalHeader?.contentType === 'html' && (
            <GlobalHeader content={globalHeader?.defaultValue} />
          )}
          <div
            id={`blkd-section-content_${page}`}
            className={styleModuleClasses(
              styles,
              'blkd-section-page',
              'main-content',
              'row',
              'main-block',
              'background-transparent'
            )}
          >
            {children}
          </div>

          <GlobalFooterBeeliked
            content={globalFooter?.defaultValue}
            beelikedFooter={beelikedFooter}
          />
        </div>
      </>

      {tags?.map((tag, index) => {
        const CustomComponent = tag?.component;
        if (CustomComponent) {
          if (CustomComponent === 'link') {
            return <link {...tag?.attribute} key={`tag-${index}`} />;
          } else if (CustomComponent === 'meta') {
            return <meta {...tag?.attribute} key={`tag-${index}`} />;
          } else {
            return <CustomComponent {...tag?.attribute} key={`tag-${index}`} />;
          }
        }
      })}

      {initial?.analytics?.code_bottom
        ?.filter((script) => script.type === AnalyticsScriptType.SCRIPT)
        .map((script, index) => (
          <script
            key={`script-${index}`}
            dangerouslySetInnerHTML={{ __html: script.content }}
          />
        ))}
    </>
  );
}
