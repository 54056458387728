import React, { HTMLProps, useEffect, useRef } from 'react';

interface SafeHTMLProps extends HTMLProps<HTMLDivElement> {
  html: string;
}

export default function SafeHTML({
  html,
  ...props
}: SafeHTMLProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const scriptTags = containerRef.current.getElementsByTagName('script');

      for (let i = 0; i < scriptTags.length; i++) {
        const script = document.createElement('script');
        const currentScript = scriptTags[i];
        script.text = currentScript.innerHTML;

        if (currentScript.src) {
          script.src = currentScript.src;
        }

        currentScript.parentNode?.replaceChild(script, currentScript);
      }
    }
  }, [html]);

  return (
    <div
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  );
}
