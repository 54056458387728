export const NEXT_PUBLIC_API_URL =
  process.env.NEXT_PUBLIC_API_URL ||
  'https://microsite-graphqldev.service.beeliked.com/graphql';
export const NEXT_PUBLIC_API_DOCKER_URL =
  process.env.NEXT_PUBLIC_API_DOCKER_URL ||
  'http://microsite-graphql:8015/local/graphql';
export const NEXT_PUBLIC_MICROSITE_API_URL =
  process.env.NEXT_PUBLIC_MICROSITE_API_URL ||
  'https://oldapi.dev.beeliked.com';
export const NEXT_PUBLIC_X_API_KEY_GRAPHQL =
  process.env.NEXT_PUBLIC_X_API_KEY_GRAPHQL ||
  'h8XhYh1q6a6NFHj8qD8gl7mRXrgnrcc31NnDn2zc';
export const NEXT_PUBLIC_ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENVIRONMENT || 'development';
export const NEXT_PUBLIC_STARTED_FREE_URL =
  'https://www.beeliked.com/get-started-for-free';
