type StylesObj = {
  [className: string]: string;
};

const styleModuleClasses = (
  stylesObj: StylesObj,
  ...classNames: (string | boolean | undefined | null | { [key: string]: boolean })[]
): string => {
  const classes = classNames
    .filter((className): className is string => typeof className === 'string')
    .map((className) => stylesObj[className] || className);

  const objectClasses = classNames[classNames.length - 1];

  if (objectClasses && typeof objectClasses === 'object') {
    const objectClassNames = Object.keys(objectClasses)
      .filter((className) => objectClasses[className])
      .map((className) => stylesObj[className] || className);
    classes.push(...objectClassNames);
  }

  return classes.join(' ');
};

export default styleModuleClasses;
