/* eslint-disable @next/next/no-img-element */
import { useEffect } from 'react';
import styles from './styles.module.scss';

interface Props {
  msg?: string;
  showMsg?: boolean;
  showButton?: boolean;
}

export default function ErrorPage({ msg, showMsg, showButton = true }: Props) {
  const reload = () => {
    window.location.reload();
  };

  useEffect(() => console.log('Error message:', msg), []);

  return (
    <div className={`container text-center mt-4 ${styles.pageError}`}>
      <div>
        <img src="/img/error-page-image.png" alt="Beeliked Error Page" />
      </div>
      {showMsg ? (
        <h1>{msg}</h1>
      ) : (
        <>
          <h1>Oh no, something went wrong!</h1>
          <p>Perhaps you can try to refresh the page, sometimes it works.</p>
        </>
      )}
      {showButton && (
        <div>
          <button className="btn btn-primary" type="button" onClick={reload}>
            Retry
          </button>
        </div>
      )}
    </div>
  );
}
