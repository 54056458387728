import { useEffect, useState } from 'react';
import useAuthContext from '@/providers/AuthProvider';
import useMicrositeContext from '@/providers/MicrositeContextProvider';
import DropdownModal from './DropdownModal';
import { DefaultModalValues, DraftModalProps } from './interface';
import styles from './styles.module.scss';

export default function DraftModal({
  refetchStartPage,
}: DraftModalProps): JSX.Element {
  const { initial, removeControlSession } = useMicrositeContext();
  const { removeAuth } = useAuthContext();
  const [defaultFields, setDefaultFields] = useState<DefaultModalValues>({
    uidField: 'auto',
    uid: '',
    email: '',
    fields: [],
  });

  useEffect(() => {
    removeControlSession();
    removeAuth();
  }, []);

  const setDefaultValues = (variables: DefaultModalValues) => {
    setDefaultFields(variables);
  };

  return (
    <div className={styles['wrapper-modal']}>
      <div className={styles['wrapper-title']}>
        Promotion in draft mode
        <span className={styles['badge']}>{initial.visibility}</span>
      </div>
      {initial.visibility === 'private' && (
        <DropdownModal
          setDefaultValues={setDefaultValues}
          defaultFields={defaultFields}
          refetchStartPage={refetchStartPage}
        />
      )}
    </div>
  );
}
