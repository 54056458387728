import { createContext, useContext, useEffect, useState } from 'react';

interface MicrositeContextLoadingImages {
  loadingImages: boolean;
  setLoadingImages: (value: boolean) => void;
}

const MicrositeLoadingImageContext = createContext({});

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export function MicrositeLoadingImageContextProvider({
  children,
}: Props): JSX.Element {
  const [loadingImages, setLoadingImages] = useState(true);

  const observeUrlChange = () => {
    let oldHref = document.location.href;
    const body = document.querySelector('body');
    const observer = new MutationObserver((mutations) => {
      if (oldHref !== document.location.href) {
        setLoadingImages(true);
        oldHref = document.location.href;
      }
    });
    observer.observe(body!, { childList: true, subtree: true });
  };

  useEffect(() => {
    observeUrlChange();
  }, []);

  return (
    <MicrositeLoadingImageContext.Provider
      value={{
        loadingImages,
        setLoadingImages,
      }}
    >
      {children}
    </MicrositeLoadingImageContext.Provider>
  );
}

export default function useMicrositeLoadingImageContext(): MicrositeContextLoadingImages {
  const context = useContext(
    MicrositeLoadingImageContext
  ) as MicrositeContextLoadingImages;
  return context;
}
