import { setPasscodeVar, setStepsVar } from '@/config/ApolloClient';
import {
  FirstLoad,
  PromotionStatus,
  PromotionVisibility,
  Segments,
  TemplateBlocks,
} from '@/modules/[slug]/interface';
import {
  getSessionString,
  removeSessionString,
  setSessionString,
  storageSupported,
} from '@/utils/setSessionString';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface UrlProperties {
  accountSlug: string;
  promotionSlug: string;
}

interface Parameters {
  key?: string;
  referrer?: string;
  inv?: string;
}

export interface Steps {
  name: string;
  checked: boolean;
}

interface MicrositeContextVariables {
  accountSlug: string;
  promotionSlug: string;
  visibility: PromotionVisibility;
  promotionStatus: PromotionStatus;
  initial: FirstLoad;
  setInitial: (data: FirstLoad) => void;
  referrer?: string;
  setFormLoaded: (formLoaded: number) => void;
  formLoaded: number;
  storedSteps: () => Steps[] | undefined;
  setStep: (step: string) => void;
  removeControlSession: (status?: string) => void;
  generateControlSteps: () => void;
  setOpenSettingDraft: (openSettingDraft: boolean) => void;
  openSettingDraft: boolean;
  aux: any;
  setAux: (aux: any) => void;
  passCodeValue: () => string;
  setPasscode: (key: string) => void;
  startPage: TemplateBlocks;
  setStartPage: (blocks: TemplateBlocks) => void;
  segments: Segments[];
  setSegments: (segments: Segments[]) => void;
  inviteLinkCode?: string;
  beelikedLoading: boolean;
  showPopups: string[];
  setShowPopups: (popups: string[]) => void;
}

interface Props {
  children?: JSX.Element | JSX.Element[];
  micrositeData: FirstLoad;
  accountSlug: string;
  promotionSlug: string;
  parameters?: Parameters;
}

const MicrositeContext = createContext({});

export function MicrositeContextProvider({
  children,
  micrositeData,
  accountSlug,
  promotionSlug,
  parameters,
}: Props): JSX.Element {
  const [urlProperties] = useState<UrlProperties>({
    accountSlug,
    promotionSlug,
  });
  const [formLoaded, setFormLoaded] = useState<number | undefined>();
  const [openSettingDraft, setOpenSettingDraft] = useState<boolean>(false);
  const [aux, setAux] = useState<any>();
  const [initial, setInitial] = useState<FirstLoad>(micrositeData);
  const [visibility] = useState<PromotionVisibility | undefined>(
    micrositeData?.visibility
  );
  const [startPage, setStartPage] = useState<TemplateBlocks>();
  const [segments, setSegments] = useState<Segments[]>();
  const [showPopups, setShowPopups] = useState<string[]>([]);
  const [promotionStatus] = useState<PromotionStatus | undefined>(
    micrositeData?.status
  );

  useEffect(() => {
    if (initial) {
      generateControlSteps();
    }
  }, [initial]);

  const beelikedLoading = useMemo(
    () => initial?.beelikedLoading || false,
    [initial]
  );

  const storedSteps = (): Steps[] => {
    const steps = storageSupported()
      ? getSessionString('steps')
      : setStepsVar();
    return steps ? JSON.parse(steps) : undefined;
  };

  const setStep = (stepName: string) => {
    const steps = storedSteps();

    if (steps) {
      const index = steps.findIndex((step) => step.name === stepName);
      steps[index].checked = true;

      const stepsParsed = JSON.stringify(steps);

      setSessionString('steps', stepsParsed);
      setStepsVar(stepsParsed);
    }
  };

  const generateControlSteps = () => {
    const steps = storedSteps();
    const stepsSerialized =
      steps ||
      initial.steps?.map((step) => ({
        name: step,
        checked: false,
      }));

    const stepsParsed = JSON.stringify(stepsSerialized);

    setSessionString('steps', stepsParsed);
    setStepsVar(stepsParsed);
  };

  const removeControlSession = (status: string = 'live') => {
    removeSessionString('steps');
    setStepsVar('');

    if (status) {
      removeSessionString('passcode');
      setPasscodeVar('');
    }
  };

  const referrer = parameters?.referrer;
  const inviteLinkCode = parameters?.inv;

  useEffect(() => {
    if (parameters?.key) {
      setPasscode(parameters.key);
    }
  }, [parameters?.key]);

  const setPasscode = (key: string) => {
    setSessionString('passcode', key);
    setPasscodeVar(key);
  };

  const passCodeValue = () => {
    return storageSupported() ? getSessionString('passcode') : setPasscodeVar();
  };

  return (
    <MicrositeContext.Provider
      value={{
        accountSlug: urlProperties.accountSlug,
        promotionSlug: urlProperties.promotionSlug,
        visibility,
        promotionStatus,
        initial,
        setInitial,
        referrer,
        setFormLoaded,
        formLoaded,
        storedSteps,
        setStep,
        removeControlSession,
        generateControlSteps,
        openSettingDraft,
        setOpenSettingDraft,
        aux,
        setAux,
        passCodeValue,
        setPasscode,
        startPage,
        setStartPage,
        segments,
        setSegments,
        inviteLinkCode,
        beelikedLoading,
        showPopups,
        setShowPopups,
      }}
    >
      {children}
    </MicrositeContext.Provider>
  );
}

export default function useMicrositeContext(): MicrositeContextVariables {
  const context = useContext(MicrositeContext) as MicrositeContextVariables;
  return context;
}
