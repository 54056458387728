export interface Initial {
  slug: string;
  accountSlug: string;
  campaignName: string;
  title: string;
  visibility: PromotionVisibility;
  updatedAt: string;
  steps: [string];
  currentStep: string;
  status: PromotionStatus;
  domain: string;
  urlThemeCss: string;
  urlTemplateCss: string;
  templatePage: Record<string, Record<string, PresetValue>>;
  templateGlobalHeader: Record<string, Record<string, PresetValue>>;
  templateGlobalFooter: Record<string, Record<string, PresetValue>>;
  segments: Segments[];
  analytics: InitialAnalytics;
  metaData: InitialMetaData;
  micrositeUrl: string;
  beelikedLoading: boolean;
}

export interface InitialAnalytics {
  code_top: InitialAnalyticsScripts[];
  code_bottom: InitialAnalyticsScripts[];
}

export interface InitialAnalyticsScripts {
  type: AnalyticsScriptType;
  content: string;
}

export type Steps = 'start' | 'execute';

export interface Segments {
  availability: SegmentsAvailability;
  extra_fields?: ExtraFields;
  preset_values: JSON;
  id: string;
  label: string;
  media?: SegmentsMedia;
}

interface ExtraFields {
  color_value: string;
  extra_text_field_value: string;
}

interface SegmentsAvailability {
  is_available: boolean;
  extra_fields?: ExtraFields;
  preset_values: JSON;
  start_countdown_secs?: string;
  end_countdown_secs?: string;
  is_used: boolean;
}

interface SegmentsMedia {
  type: string;
  url: string;
  service: string;
  mimetype: string;
}

export interface PresetValue {
  defaultValue: string;
  contentType: string;
}

export enum PromotionStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  ENDED = 'ended',
  SCHEDULED = 'scheduled',
}

export enum PromotionVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface InitialMetaData {
  title: string;
  favicon?: string;
  metas: InitialMetaDataTags[];
}

interface InitialMetaDataTags {
  type: MetaDataType;
  name: string;
  content: string;
}

export enum MetaDataType {
  NAME = 'name',
  PROPERTY = 'property',
}

export enum PromotionSteps {
  START = 'start',
  ENTER = 'enter',
  EXECUTE = 'execute',
  RESULT = 'result',
}

export interface FirstLoad {
  slug: string;
  accountSlug: string;
  campaignName: string;
  title: string;
  visibility: PromotionVisibility;
  updatedAt: string;
  steps: [string];
  currentStep: string;
  status: PromotionStatus;
  domain: string;
  urlThemeCss: string;
  urlTemplateCss: string;
  segments: Segments[];
  analytics: InitialAnalytics;
  metaData: InitialMetaData;
  beelikedLoading: boolean;
}

export interface PageTemplateStart {
  templatePage: TemplateBlocks;
  templateGlobalHeader: TemplateBlocks;
  templateGlobalFooter: TemplateBlocks;
  segments: Segments[];
  token: string | null;
  resultStatus: PageTemplateStartResultStatus;
  showPopups: string[];
}

interface PageTemplateStartResultStatus {
  status: string;
  message: string;
  captchaSiteKey: string;
  msgReaccess: string;
}
export type TemplateBlocks = Record<string, Record<string, PresetValue>>;

export interface MicrositeTemplateExecutePageData {
  templatePage: TemplateBlocks;
  segments: Segments[];
}

export enum AnalyticsScriptType {
  IMPORT = 'import',
  SCRIPT = 'script',
}
