import apolloClient from '@/config/ApolloClient';
import { FirstLoad } from '@/modules/[slug]/interface';
import { AuthProvider } from '@/providers/AuthProvider';
import { MicrositeContextProvider } from '@/providers/MicrositeContextProvider';
import getInitial from '@/services/initial';
import '@/styles/bootstrap/custom.scss';
import '@/styles/common/animation.scss';
import '@/styles/common/design-system.scss';
import '@/styles/index.css';
import { ApolloProvider } from '@apollo/client';
import App, { AppContext, AppProps } from 'next/app';
import { lazy } from 'react';
import Layout from './[slug]/layout';
import { MicrositeLoadingImageContextProvider } from './[slug]/loadingContext';

const Passcode = lazy(() => import('@/components/Passcode'));
const ErrorPage = lazy(() => import('@/components/ErrorPage'));

interface Props extends AppProps {
  initial: FirstLoad;
  accountSlug: string;
  promotionSlug: string;
  page: 'index' | 'slug' | 'error';
  message: string;
  validationCaptchaSiteKey: string;
  passcodeKey: string;
  referrer?: string;
  inv?: string;
}

function MyApp({
  Component,
  pageProps,
  initial,
  accountSlug,
  promotionSlug,
  page,
  message,
  validationCaptchaSiteKey,
  passcodeKey,
  referrer,
  inv,
}: Props) {
  const parameters = {
    key: passcodeKey,
    referrer,
    inv,
  };

  if (validationCaptchaSiteKey) {
    return (
      <ApolloProvider client={apolloClient}>
        <Passcode
          validationCaptchaSiteKey={validationCaptchaSiteKey}
          accountSlug={accountSlug}
          promotionSlug={promotionSlug}
        />
      </ApolloProvider>
    );
  }

  if (page === 'index') {
    return <Component {...pageProps} />;
  }

  if (page === 'error') {
    return (
      <ErrorPage
        msg={
          message.indexOf('Promotion not found') > -1
            ? 'The URL you have entered is invalid. Please check and try again.'
            : message
        }
        showMsg={message.indexOf('Promotion not found') > -1}
        showButton={message.indexOf('Promotion not found') === -1}
      />
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <MicrositeContextProvider
        micrositeData={initial}
        accountSlug={accountSlug}
        promotionSlug={promotionSlug}
        parameters={parameters}
      >
        <MicrositeLoadingImageContextProvider>
          <AuthProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </AuthProvider>
        </MicrositeLoadingImageContextProvider>
      </MicrositeContextProvider>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  let appProps = {};
  if (context.Component.getInitialProps) {
    appProps = await App.getInitialProps(context);
  }

  const values = {
    initial: {},
    accountSlug: '',
    promotionSlug: '',
    page: 'index',
    message: '',
    validationCaptchaSiteKey: '',
    passcodeKey: '',
    referrer: '',
    inv: undefined,
  };

  const { router, ctx } = context;
  const { req } = ctx;

  const referrer = router.query.referrer?.toString();

  if (router.route === '/') {
    return values;
  }

  let initialValues;
  let inv;
  if (req?.headers) {
    const host =
      req?.headers?.['x-forwarded-host'] || req?.headers?.['host'] || '';
    const accountSlug = host.toString().split('.')[0];
    let promotionSlug;
    let key;
    if (req?.headers.accept?.includes('image/*')) {
      const url = new URL(req?.headers?.referer?.toString() ?? '');
      promotionSlug = url.pathname.substring(1);
      const searchParams = new URLSearchParams(url.search);
      key = searchParams.get('key');
      inv = searchParams.get('inv');
    } else {
      promotionSlug = router?.query?.slug ?? '';
      key = router?.query?.key?.toString();
      inv = router?.query?.inv?.toString();
    }

    initialValues = await getInitial(
      host.toString(),
      promotionSlug.toString(),
      key?.toString()
    );

    if (initialValues.errorMessage !== '') {
      values.page = 'error';
      values.accountSlug = accountSlug;
      values.promotionSlug = promotionSlug.toString();
      values.validationCaptchaSiteKey = initialValues.validationCaptchaSiteKey;
      values.message = initialValues.errorMessage + ' - host: ' + host;
      return values;
    }
  }

  const response = {
    ...appProps,
    ...initialValues,
    referrer,
    cacheDate: new Date(),
    inv: inv,
  };

  return response;
};

export default MyApp;
