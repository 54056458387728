const captureAccountPromotion = (
  host: string,
  pathname: string,
  domains: string[]
): { accountSlug: string; promotionSlug: string; externalDomain: string } => {
  let accountSlug = '';
  let promotionSlug = '';
  let externalDomain = '';
  const domain = domains.find((domain) => host.indexOf(domain) > -1);

  if (domain) {
    const position = host.indexOf(domain);
    accountSlug = host.slice(0, position - 1);
  } else {
    externalDomain = host;
  }

  const pathnameParsed = pathname[0] === '/' ? pathname.slice(1) : pathname;

  const slashPosition = pathnameParsed.indexOf('/');
  promotionSlug =
    slashPosition > -1
      ? pathnameParsed.slice(0, slashPosition)
      : pathnameParsed;

  return { accountSlug, promotionSlug, externalDomain };
};

export default captureAccountPromotion;
