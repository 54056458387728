import Button from '../Inputs/Button';
import IconButton from '../Inputs/IconButton';
import Overlay from '../Overlay';
import { ModalProps } from './interface';
import styles from './styles.module.scss';

export default function Modal({
  title,
  size,
  children,
  leftButton,
  rightButton,
  footerDescription,
  position,
  spacingOverlay,
  callback,
}: ModalProps) {
  return (
    <>
      <Overlay
        spacing={spacingOverlay}
        color="rgba(0, 0, 0, 0.7)"
        backgroundBlur="2.5px"
        callback={callback}
      />
      <div
        className={`
        ${styles['modal']}
        ${styles['fade-in']}
        ${size ? styles[size] : styles['big']}`}
        style={
          position
            ? {
                ...position,
              }
            : { left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }
        }
      >
        <header>
          <title>{title}</title>
          <IconButton callback={callback}>close</IconButton>
        </header>
        <main>{children}</main>
        <footer
          className={
            !leftButton && rightButton
              ? 'justify-content-end'
              : 'justify-content-between'
          }
        >
          {footerDescription && footerDescription}
          {leftButton && <Button {...leftButton} />}
          {rightButton && <Button {...rightButton} />}
        </footer>
      </div>
    </>
  );
}
