import { gql } from '@apollo/client';

export const MICROSITE_FIRST_LOAD = gql`
  query micrositeFirstLoad(
    $accountSlug: String
    $promotionSlug: String!
    $externalDomain: String
  ) {
    micrositeFirstLoad(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
      externalDomain: $externalDomain
    ) {
      slug
      accountSlug
      campaignName
      title
      visibility
      updatedAt
      steps
      currentStep
      status
      domain
      urlThemeCss
      urlTemplateCss
      analytics {
        code_top {
          type
          content
        }
        code_bottom {
          type
          content
        }
      }
      metaData {
        title
        favicon
        metas {
          type
          name
          content
        }
      }
      micrositeUrl
      beelikedLoading
    }
  }
`;
