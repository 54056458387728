import { ButtonProps } from './interface';
import styles from './styles.module.scss';

export default function Button({
  title,
  firstIcon,
  lastIcon,
  variant,
  disabled,
  callback,
  loading,
  textColor,
  size,
}: ButtonProps) {
  return (
    <button
      className={`
      ${styles['button']}
      ${variant ? styles[variant] : styles['primary']}
      ${textColor ? styles['text-' + textColor] : ''}
      ${size ? styles[size] : styles['md']}
      `}
      disabled={disabled}
      onClick={callback}
    >
      {loading ? (
        <span className={'spinner-border spinner-border-sm'} />
      ) : (
        <div className={styles['content']}>
          {firstIcon?.name && (
            <span
              style={{ transform: firstIcon?.style }}
              className="material-symbols-rounded"
            >
              {firstIcon?.name}
            </span>
          )}
          <span>{title}</span>
          {lastIcon?.name && (
            <span
              style={{ transform: lastIcon?.style }}
              className="material-symbols-rounded"
            >
              {lastIcon?.name}
            </span>
          )}
        </div>
      )}
    </button>
  );
}
