export const storageSupported = (): boolean => {
  try {
    const testKey = '__blkd_check_storage__';
    sessionStorage.setItem(testKey, 'worked?');
    sessionStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

export const setSessionString = (key: string, value: string): void => {
  if (storageSupported()) {
    sessionStorage.setItem(key, value);
  }
};

export const getSessionString = (key: string): string | undefined => {
  if (storageSupported()) {
    const data = sessionStorage.getItem(key);
    return !data ? undefined : data;
  }
  return;
};

export const removeSessionString = (key: string): void => {
  if (storageSupported()) {
    sessionStorage.removeItem(key);
  }
};
