import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import getKeyByValue from '../../utils/getKeyByValue';
import Button from '../DesignSystem/Inputs/Button';
import Select from '../DesignSystem/Inputs/Selects/Select';
import Modal from '../DesignSystem/Modal';
import { DRAFT_SIGN_ON, GENERATE_DRAFT_FORM } from './graphql';
import useMicrositeContext from '../../providers/MicrositeContextProvider';
import validateEmail from '../../utils/validateEmail';
import {
  DefaultModalValuesFields,
  DropdownModalProps,
  MicrositeDraftSignOn,
  MicrositeGenerateDraftForm,
} from './interface';
import useAuthContext from '@/providers/AuthProvider';
import styles from './styles.module.scss';

export default function DropdownModal({
  setDefaultValues,
  defaultFields,
  refetchStartPage,
}: DropdownModalProps): JSX.Element {
  const [inviteLinkCode, setInviteLinkCode] = useState<string>(
    defaultFields.uid
  );
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [openSignOn, setOpenSignOn] = useState<boolean>(false);
  const [uidField, setUidField] = useState<string>(defaultFields.uidField);
  const [emailError, setEmailError] = useState<string>('');
  const [email, setEmail] = useState<string>(defaultFields.email);
  const [dataField, setDataField] = useState<DefaultModalValuesFields[]>([]);

  const {
    openSettingDraft,
    setOpenSettingDraft,
    removeControlSession,
    accountSlug,
    promotionSlug,
    passCodeValue,
    setPasscode,
  } = useMicrositeContext();
  const { removeAuth, signOn, setAuth } = useAuthContext();

  const auth = signOn;

  const route = useRouter();

  useEffect(() => {
    if (openSettingDraft) {
      setShowSettingsModal(true);
    }
  }, [openSettingDraft]);

  const handleSettingsModal = () => {
    setShowSettingsModal((showSettingsModal) => !showSettingsModal);
    if (showSettingsModal) {
      setOpenSettingDraft(false);
    }
  };

  const { data: dataGenerateDraftForm } = useQuery<{
    micrositeGenerateDraftForm: MicrositeGenerateDraftForm;
  }>(GENERATE_DRAFT_FORM, {
    fetchPolicy: 'no-cache',
    variables: {
      accountSlug,
      promotionSlug,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'GENERATE_DRAFT_FORM',
        apolloError: error,
      });
    },
  });

  const dataUniqueUid = useMemo(() => {
    return (
      dataGenerateDraftForm?.micrositeGenerateDraftForm?.user_uid
        ?.data_unique_uid ?? ''
    );
  }, [dataGenerateDraftForm]);

  const [draftSignOn, { loading: loadingDraftSignOn }] = useMutation<{
    micrositeDraftSignOn: MicrositeDraftSignOn;
  }>(DRAFT_SIGN_ON, {
    onCompleted: (response) => {
      if (response.micrositeDraftSignOn.status === 'success') {
        setAuth(response.micrositeDraftSignOn.token);
        const dataFieldSerialized = Object.values(dataField).map((value) => {
          return {
            name: getKeyByValue(dataField, value),
            value: value as unknown as string,
          };
        });

        setDefaultValues({
          uidField: uidField,
          uid: dataUniqueUid,
          email: email,
          fields: dataFieldSerialized,
        });

        setInviteLinkCode(dataUniqueUid);

        refetchStartPage({
          accountSlug,
          promotionSlug,
          inviteLinkCode: dataUniqueUid,
        });
        handleSettingsModal();
      }
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'VALID_PASSCODE',
        apolloError: error,
      });
    },
  });

  const handleUidField = (value: string) => {
    setUidField(value);
    if (value === 'email') {
      setEmail('');
    }
  };

  const choices = useMemo(() => {
    if (dataGenerateDraftForm?.micrositeGenerateDraftForm?.uid_field?.choices) {
      const choices =
        dataGenerateDraftForm.micrositeGenerateDraftForm?.uid_field?.choices;
      const values = Object.keys(choices)
        .filter((choice) => choice === 'auto' || choice === 'email')
        .map((choice) => {
          const value = choice.toString();
          const label = value[0].toUpperCase() + value.slice(1);
          return {
            value,
            label,
          };
        });

      return values;
    }
    return undefined;
  }, [dataGenerateDraftForm]);

  const fields = useMemo(
    () => dataGenerateDraftForm?.micrositeGenerateDraftForm?.fields ?? [],
    [dataGenerateDraftForm]
  );

  const generate = () => {
    if (uidField === 'email') {
      let errorMessageEmail = '';
      if (email === '') {
        errorMessageEmail = 'Email is required';
      }
      if (!validateEmail(email) && errorMessageEmail === '') {
        errorMessageEmail = 'Email is invalid';
      }
      if (errorMessageEmail) {
        setEmailError(errorMessageEmail);
        return false;
      }
    }

    const dataFieldSerialized = Object.values(dataField).map((value) => {
      return { name: getKeyByValue(dataField, value), value };
    });

    const variables = {
      accountSlug,
      promotionSlug,
      uidField: uidField,
      userUid: uidField === 'auto' ? dataUniqueUid : email,
      fields: dataFieldSerialized,
    };

    if (dataUniqueUid) {
      const passcode = route.query.key?.toString();
      if (passcode) {
        setPasscode(passcode);
      }
      draftSignOn({
        variables,
      });
    }
  };

  const handleField = (value: ChangeEvent<HTMLInputElement>) => {
    setEmail(value.target.value);
    setEmailError('');
  };

  const handleDinamicField = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setDataField({ ...dataField, [name]: value });
  };

  const signout = () => {
    const passcode = passCodeValue();
    const key = passcode ? `?key=${passcode}` : '';

    setInviteLinkCode('');
    setUidField('auto');
    setEmail('');
    removeControlSession();
    removeAuth();

    window.location.href = `/${promotionSlug}${key}`;
  };

  const toggleSignOnModal = () => {
    setOpenSignOn((openSignOn) => !openSignOn);
  };

  return (
    <>
      <Button
        title="Settings"
        variant="secondary"
        lastIcon={{ name: 'expand_more' }}
        size="sm"
        callback={handleSettingsModal}
      />

      {showSettingsModal && (
        <>
          <Modal
            title="Settings"
            position={{ right: '0.75rem' }}
            spacingOverlay="3rem 0 0 0"
            size="small"
            {...(inviteLinkCode === '' && {
              rightButton: {
                title: 'Generate',
                variant: 'primary',
                callback: generate,
                loading: loadingDraftSignOn,
              },
            })}
            {...(inviteLinkCode && {
              leftButton: {
                title: 'Sign out',
                variant: 'secondary',
                firstIcon: {
                  name: 'exit_to_app',
                  style: 'rotate(180deg)',
                },
                size: 'sm',
                callback: signout,
              },
            })}
            callback={handleSettingsModal}
          >
            <div className={styles['wrapper-content']}>
              <span>Generate Invite Link</span>
              <Select
                label="UID Field"
                placeholder="Choose a result"
                defaultValue={uidField.toString()}
                onChange={handleUidField}
                options={choices}
                disabled={Boolean(auth)}
              />

              {inviteLinkCode && uidField !== 'email' && (
                <div>
                  <label className="form-label">UID</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={inviteLinkCode}
                    disabled={true}
                  />
                </div>
              )}

              {uidField === 'email' && (
                <div>
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleField}
                    defaultValue={email}
                    disabled={Boolean(auth)}
                  />
                  {emailError && (
                    <div className="form-text text-danger">{emailError}</div>
                  )}
                </div>
              )}

              {fields?.map((field) => (
                <div key={`field-${field.key}`}>
                  <label className="form-label">{field.label}</label>
                  <input
                    type="text"
                    name={field.key}
                    placeholder={field.placeholder}
                    className="form-control"
                    onChange={handleDinamicField}
                    disabled={Boolean(auth)}
                    defaultValue={
                      defaultFields.fields.find(
                        (defaultField) => defaultField.name === field.key
                      )?.value
                    }
                  />
                </div>
              ))}
            </div>
          </Modal>
        </>
      )}
    </>
  );
}
