import apolloClient from '@/config/ApolloClient';
import { DOMAINS } from '@/config/Env/Domains';
import { MICROSITE_FIRST_LOAD } from '@/modules/[slug]/gql';
import { Initial } from '@/modules/[slug]/interface';
import captureAccountPromotion from '@/utils/captureAccountPromotion';
import getDomainFromUrl from '@/utils/getDomainFromUrl';
import { ApolloError, isApolloError } from '@apollo/client';

const getInitial = async (
  host: string,
  pathname: string,
  key?: string,
  inviteLinkCode?: string
): Promise<{
  initial: Initial | undefined;
  accountSlug: string;
  promotionSlug: string;
  errorMessage: string;
  validationCaptchaSiteKey: string;
  passcodeKey?: string;
}> => {
  const urlProperties = captureAccountPromotion(host, pathname, DOMAINS);

  const externalDomain = urlProperties.externalDomain
    ? urlProperties.externalDomain.replace(/:\d+$/, '')
    : '';

  let micrositeData: Initial | undefined;
  let errorMessage = '';
  let validationCaptchaSiteKey = '';

  try {
    const { data } = await apolloClient.query({
      query: MICROSITE_FIRST_LOAD,
      fetchPolicy: 'no-cache',
      variables: {
        accountSlug: urlProperties.accountSlug,
        promotionSlug: urlProperties.promotionSlug,
        externalDomain,
        inviteLinkCode,
      },
      ...(key && {
        context: {
          headers: {
            passcode: key,
          },
        },
      }),
    });
    micrositeData = data.micrositeFirstLoad;
  } catch (error: unknown) {
    if (isApolloError(error as Error)) {
      const apolloError = error as ApolloError;

      const statusCode =
        apolloError?.graphQLErrors?.[0]?.extensions?.statusCode || 500;

      errorMessage = apolloError.message;
      if (statusCode === 403) {
        const validations = apolloError?.graphQLErrors[0]?.extensions as Record<
          string,
          string
        >;
        const validationsData = validations?.data as any;
        validationCaptchaSiteKey = validationsData.captchaSiteKey;
      }
    }
  }

  const url = getDomainFromUrl(micrositeData?.micrositeUrl);
  const hostWithoutPort = host.replace(/:\d+$/, '');
  if (url !== hostWithoutPort) {
    errorMessage = 'Promotion not found';
  }

  return {
    initial: micrositeData,
    accountSlug: micrositeData ? micrositeData.accountSlug : '',
    promotionSlug: urlProperties.promotionSlug,
    errorMessage,
    validationCaptchaSiteKey,
    passcodeKey: key,
  };
};

export default getInitial;
