import { NEXT_PUBLIC_STARTED_FREE_URL } from '@/config/Env';
import setIimportantToInlineStyle from '@/utils/setIimportantToInlineStyle';
import Image from 'next/image';
import { useEffect, useRef } from 'react';

export default function BeelikedFooter(): JSX.Element {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const refLink = useRef<HTMLAnchorElement | null>(null);

  const setImportantStyles = (node: HTMLElement) => {
    setIimportantToInlineStyle(node, {
      display: 'flex',
      opacity: '1',
      'justify-content': 'center',
      visibility: 'visible',
      'min-height': '2.75rem',
    });
  };

  useEffect(() => {
    if (refContainer.current) {
      setImportantStyles(refContainer.current);
    }
    if (refLink.current) {
      setImportantStyles(refLink.current);
    }
  }, []);

  useEffect(() => {
    const updateStyles = () => {
      if (refContainer.current) {
        setImportantStyles(refContainer.current);
      }
      if (refLink.current) {
        setImportantStyles(refLink.current);
      }
      setTimeout(updateStyles, 15000);
    };

    const timeoutId = setTimeout(updateStyles, 15000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div ref={refContainer} className="beeliked">
      <a
        href={NEXT_PUBLIC_STARTED_FREE_URL}
        target="_blank"
        rel="noreferrer"
        ref={refLink}
      >
        <Image
          src="/img/powerByBeeliked.svg"
          alt="Powered by Beeliked"
          height={33}
          width={184}
        />
      </a>
    </div>
  );
}
