const setIimportantToInlineStyle = (
  node: HTMLElement,
  styles: Record<string, string>
) => {
  if (Object.keys(styles).length === 0) {
    return;
  }
  Object.keys(styles).forEach((property) => {
    node.style.setProperty(property, styles[property], 'important');
  });
};

export default setIimportantToInlineStyle;
