import React from 'react';
import { IconButtonProps } from './interface';
import styles from './styles.module.scss';

export default function IconButton({
  children,
  callback,
  classname,
  text,
}: IconButtonProps) {
  return (
    <button
      className={`
      ${styles['icon-button']} 
      ${classname} 
      `}
      onClick={callback}
    >
      <span className={`material-symbols-rounded`}>{children}</span>
      {text && <span>{text}</span>}
    </button>
  );
}
