import { gql } from '@apollo/client';

export const GENERATE_DRAFT_FORM = gql`
  query micrositeGenerateDraftForm(
    $accountSlug: String!
    $promotionSlug: String!
  ) {
    micrositeGenerateDraftForm(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
    ) {
      uid_field {
        label
        choices {
          email
          auto
        }
        data_email_field
        value
      }
      user_uid {
        label
        data_unique_uid
      }
      fields {
        id
        key
        type
        name
        label
        placeholder
      }
    }
  }
`;

export const DRAFT_SIGN_ON = gql`
  mutation micrositeDraftSignOn(
    $accountSlug: String!
    $promotionSlug: String!
    $uidField: String!
    $userUid: String!
    $fields: [MicrositeSignOnFieldsInput]
  ) {
    micrositeDraftSignOn(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
      uidField: $uidField
      userUid: $userUid
      fields: $fields
    ) {
      token
      status
    }
  }
`;
