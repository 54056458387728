import useAuthContext from '@/providers/AuthProvider';
import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { PageTemplateStart } from '../interface';

const PAGE_START = gql`
  query micrositeStartData(
    $accountSlug: String!
    $promotionSlug: String!
    $inviteLinkCode: String
  ) {
    micrositeStartData(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
      inviteLinkCode: $inviteLinkCode
    ) {
      templatePage
      templateGlobalHeader
      templateGlobalFooter
      segments {
        id
        label
        feedback
        extra_fields {
          color_value
          extra_text_field_value
        }
        preset_values
        hasPrize
        availability {
          is_used
          is_available
          start_countdown_secs
          end_countdown_secs
        }
        media {
          type
          url
          service
          mimetype
        }
      }
      token
      resultStatus {
        status
        message
        captchaSiteKey
        msgReaccess
      }
      showPopups
    }
  }
`;

export default function usePageStart(): {
  loading: boolean;
  data?: PageTemplateStart;
  runPageStart?: (
    accountSlug: string,
    promotionSlug: string,
    inviteLinkCode?: string
  ) => void;
} {
  const { setAuth } = useAuthContext();

  const [run, { data, loading }] = useLazyQuery<{
    micrositeStartData: PageTemplateStart;
  }>(PAGE_START, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      if (response.micrositeStartData.token) {
        setAuth(response.micrositeStartData.token);
      }
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'PAGE_START',
        apolloError: error,
      });
    },
  });

  const runPageStart = (
    accountSlug: string,
    promotionSlug: string,
    inviteLinkCode?: string
  ) => {
    run({
      variables: {
        accountSlug,
        promotionSlug,
        inviteLinkCode,
      },
    });
  };

  if (loading) {
    return {
      loading,
    };
  }

  return {
    loading,
    data: data?.micrositeStartData,
    runPageStart,
  };
}
