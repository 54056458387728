import { NEXT_PUBLIC_MICROSITE_API_URL } from '@/config/Env';
import { AnalyticsScriptType } from '@/modules/[slug]/interface';
import htmlTagStringToReactComponent from '@/utils/htmlTagStringToReactComponent';
import Head from 'next/head';
import { useMemo } from 'react';
import { MetaDataProps } from './interface';

export default function MetaData({
  metaData,
  urlTemplateCss,
  urlThemeCss,
  analytics,
  campaignName,
}: MetaDataProps) {
  const tags = useMemo(() => {
    if (analytics?.code_top) {
      return analytics.code_top
        .filter((script) => script.type === AnalyticsScriptType.IMPORT)
        .map((tag) => htmlTagStringToReactComponent(tag.content));
    }
    return undefined;
  }, [analytics?.code_top]);

  const urlGameCss = useMemo(() => {
    switch (campaignName?.toLocaleLowerCase()) {
      case 'wheel of fortune':
        return '/spin3Style.css';
      case 'slot machine':
        return '/slotMachineStyle.css';
      case 'mystery envelope v2':
        return '/openTheEnvelopeStyle.css';
      case 'scratch off':
        return '/scratchCard2Style.css';
      case 'score a goal':
      case 'kick n score':
      case 'shoot hoops':
      case 'roll the dice':
        return '/carouselStyle.css';
    }
  }, []);

  return (
    <Head>
      {metaData?.title && <title>{metaData.title}</title>}
      {metaData?.favicon && (
        <link rel="shortcut icon" href={metaData.favicon} />
      )}
      {metaData?.metas.map((meta, index) => (
        <meta
          key={`meta-data-${index}`}
          {...{ [meta.type]: meta.name }}
          content={meta.content}
        />
      ))}

      {urlTemplateCss && (
        <link
          rel="stylesheet"
          href={`${NEXT_PUBLIC_MICROSITE_API_URL}${urlTemplateCss}`}
        />
      )}
      {urlThemeCss && (
        <link
          rel="stylesheet"
          href={`${NEXT_PUBLIC_MICROSITE_API_URL}${urlThemeCss}`}
        />
      )}

      {urlGameCss && <link rel="stylesheet" href={urlGameCss} />}

      <link rel="stylesheet" href="/enterButton.css" />

      {tags?.map((tag, index) => {
        const CustomComponent = tag?.component;
        if (CustomComponent) {
          if (CustomComponent === 'link') {
            return <link {...tag?.attribute} key={`tag-${index}`} />;
          } else if (CustomComponent === 'meta') {
            return <meta {...tag?.attribute} key={`tag-${index}`} />;
          } else {
            return <CustomComponent {...tag?.attribute} key={`tag-${index}`} />;
          }
        }
      })}

      {analytics?.code_top
        ?.filter((script) => script.type === AnalyticsScriptType.SCRIPT)
        .map((script, index) => (
          <script
            key={`script-${index}`}
            dangerouslySetInnerHTML={{ __html: script.content }}
          />
        ))}
    </Head>
  );
}
