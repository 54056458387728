import BeelikedFooter from '../BeelikedFooter';
import SafeHTML from '../SafeHTML';
import styles from './styles.module.scss';

interface Props {
  content?: string;
  beelikedFooter: boolean;
}

export default function GlobalFooterBeeliked({
  content,
  beelikedFooter,
}: Props): JSX.Element {
  const setStyleBlocker = (node: HTMLElement) => {
    node.style.setProperty('display', 'flex', 'important');
    node.style.setProperty('opacity', '1', 'important');
    node.style.setProperty('justify-content', 'center', 'important');
    node.style.setProperty('visibility', 'visible', 'important');
    node.style.setProperty('min-height', '44px', 'important');
  };

  return (
    <div
      id="blkd-section-footer"
      className="blkd-section-page row mt-auto"
      ref={(node) => {
        if (node) {
          setInterval(() => {
            setStyleBlocker(node);
          }, 1000 * 15);
          setStyleBlocker(node);
        }
      }}
    >
      {beelikedFooter && <BeelikedFooter />}
      <SafeHTML
        html={content || ''}
        id="blkd-section-footer-block-template-footer"
        className={`blkd-block col-12 blkd-block-template-footer ${styles['blkd-block']}`}
      />
    </div>
  );
}
