import React, { useEffect, useState } from 'react';
import { OverlayProps, OverlayStyles } from './interface';
import styles from './styles.module.scss';

export default function Overlay({
  spacing,
  color,
  backgroundBlur,
  callback,
}: OverlayProps) {
  const [style, setStyle] = useState<OverlayStyles>();

  useEffect(() => {
    setStyle({
      inset: spacing,
      background: color,
      backdropFilter: `blur(${backgroundBlur})`,
    });
  }, []);

  return (
    <div className={styles['overlay']} style={style} onClick={callback}></div>
  );
}
