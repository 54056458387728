import SafeHTML from '../SafeHTML';

interface Props {
  content: string;
}

export default function GlobalHeader({ content }: Props): JSX.Element {
  return (
    <div id="blkd-section-header" className="blkd-section-page row">
      <SafeHTML
        html={content}
        className="blkd-block col-12 blkd-block-template-header"
      />
    </div>
  );
}
