import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SelectProps } from '../interface';
import style from '../styles.module.scss';

export default function Select({
  label,
  placeholder,
  onChange,
  defaultValue,
  options,
  disabled,
  ...rest
}: SelectProps) {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectLabel, setSelectLabel] = useState<string>('');

  useEffect(() => {
    if (options?.length && defaultValue) {
      const option = options.find((option) => option.value === defaultValue);
      setSelectLabel(option?.label || '');
    }
  }, [options, defaultValue]);

  const handleSelect = (value: string) => {
    onChange(value);
    setShowOptions(false);

    const option = options?.find((option) => option.value === defaultValue);
    setSelectLabel(option?.label || '');
  };

  return (
    <div className={style['dropdown-wrapper']} {...rest}>
      <label>{label}</label>
      <div className={style['wrapper-select']}>
        <input
          placeholder={placeholder}
          className={style['select-dropdown']}
          value={selectLabel}
          readOnly
          disabled={disabled}
          onFocus={() => !disabled && setShowOptions(true)}
        />
        {!disabled && (
          <span className="material-symbols-rounded">expand_more</span>
        )}
      </div>
      {showOptions && !disabled && (
        <>
          <ul
            className={classNames(style['dropdown-select'], {
              [style['focus']]: showOptions,
            })}
          >
            {options?.map((outcome) => (
              <li
                key={outcome.value}
                onClick={() => handleSelect(outcome.value?.toString() ?? '')}
              >
                {outcome.label}
              </li>
            ))}
          </ul>
          <div
            className={style['select-overlay']}
            onClick={() => setShowOptions(false)}
          />
        </>
      )}
    </div>
  );
}
