import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  makeVar,
} from '@apollo/client';
import Swal from 'sweetalert2';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getSessionString, storageSupported } from '@/utils/setSessionString';
import {
  NEXT_PUBLIC_API_DOCKER_URL,
  NEXT_PUBLIC_API_URL,
  NEXT_PUBLIC_ENVIRONMENT,
  NEXT_PUBLIC_X_API_KEY_GRAPHQL,
} from '../Env';

export const setAuthVar = makeVar<string>('');
export const setPasscodeVar = makeVar<string>('');
export const setStepsVar = makeVar<string>('');

const httpLink = new HttpLink({
  uri:
    typeof window === 'undefined' && NEXT_PUBLIC_ENVIRONMENT === 'local'
      ? NEXT_PUBLIC_API_DOCKER_URL
      : NEXT_PUBLIC_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = storageSupported() ? getSessionString('auth') : setAuthVar();
  const passcode = storageSupported()
    ? getSessionString('passcode')
    : setPasscodeVar();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-api-key': NEXT_PUBLIC_X_API_KEY_GRAPHQL,
      ...(passcode && { passcode }),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].extensions?.code === 'UNAUTHENTICATED') {
      Swal.fire({
        title: 'Your session has expired. Please try again',
        confirmButtonText: 'Try again',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.assign(`/${window.location.pathname.split('/')[1]}`);
        }
      });
    } else if (graphQLErrors[0].extensions?.code !== 'VALIDATION_ERROR') {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        )
      );
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default apolloClient;
