import { DOMParser } from 'xmldom';

const htmlTagStringToReactComponent = (str: string) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(str, 'text/html');

  if (dom) {
    const NodeName = dom.documentElement.nodeName.toLowerCase();

    const values = Object.values(dom.documentElement.attributes).map((item) => {
      return [item.name, item.value];
    });

    const attributes = Object.fromEntries(values);

    const transformedAttributes = { ...attributes };
    Object.keys(transformedAttributes).forEach((key) => {
      if (transformedAttributes[key] === '') {
        transformedAttributes[key] = true;
      }
    });

    return { component: NodeName, attribute: transformedAttributes };
  }
};

export default htmlTagStringToReactComponent;
